define('emberfire-utils/adapters/firebase-flex', ['exports', 'ember-inflector', 'ember-platform', 'ember-runloop', 'ember-data/adapter', 'ember-computed', 'ember-owner/get', 'ember-service/inject'], function (exports, _emberInflector, _emberPlatform, _emberRunloop, _adapter, _emberComputed, _get, _inject) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function (obj) {
    return typeof obj;
  } : function (obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
  };

  var RSVP = Ember.RSVP;
  exports.default = _adapter.default.extend({
    defaultSerializer: '-firebase-flex',

    /**
     * @type {Ember.Service}
     * @default
     * @readonly
     */
    firebase: (0, _inject.default)(),

    /**
     * @type {Object}
     * @default
     */
    trackedListeners: {},

    /**
     * @type {Object}
     * @default
     */
    trackedQueries: {},

    /**
     * @type {Ember.Service}
     * @default
     * @readonly
     */
    fastboot: (0, _emberComputed.default)(function () {
      return (0, _get.default)(this).lookup('service:fastboot');
    }),

    /**
     * @return {string} Push ID
     */
    generateIdForRecord: function generateIdForRecord() {
      return this.get('firebase').push().key;
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {DS.Snapshot} snapshot
     * @return {Promise} Resolves when create record succeeds
     */
    createRecord: function createRecord(store, type, snapshot) {
      var _this = this;

      return this.updateRecord(store, type, snapshot).then(function () {
        _this._setupValueListener(store, type.modelName, snapshot.id);
      });
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {DS.Snapshot} snapshot
     * @return {Promise} Resolves when update record succeeds
     */
    updateRecord: function updateRecord(store, type, snapshot) {
      var _this2 = this;

      return new RSVP.Promise((0, _emberRunloop.bind)(this, function (resolve, reject) {
        var serializedSnapshot = _this2.serialize(snapshot);
        var serializedInclude = _this2._serializeInclude(snapshot);
        var fanout = (0, _emberPlatform.assign)({}, serializedSnapshot, serializedInclude);

        _this2.get('firebase').update(fanout, (0, _emberRunloop.bind)(_this2, function (error) {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        }));
      }));
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {string} id
     * @param {DS.Snapshot} [snapshot={}]
     * @return {Promise} Resolves with the fetched record
     */
    findRecord: function findRecord(store, type, id) {
      var _this3 = this;

      var snapshot = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      return new RSVP.Promise((0, _emberRunloop.bind)(this, function (resolve, reject) {
        var modelName = type.modelName;
        var onValue = (0, _emberRunloop.bind)(_this3, function (snapshot) {
          if (snapshot.exists()) {
            _this3._setupValueListener(store, modelName, id);
            ref.off('value', onValue);
            resolve(_this3._getGetSnapshotWithId(snapshot));
          } else {
            reject();
          }
        });

        var ref = _this3._getFirebaseReference(modelName, id);

        if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('path')) {
          var path = snapshot.adapterOptions.path + '/' + id;

          ref = _this3.get('firebase').child(path);
        }

        ref.on('value', onValue);
      }));
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @return {Promise} Resolves with the fetched records
     */
    findAll: function findAll(store, type) {
      var _this4 = this;

      return new RSVP.Promise((0, _emberRunloop.bind)(this, function (resolve, reject) {
        var modelName = type.modelName;
        var ref = _this4._getFirebaseReference(modelName);

        ref.on('value', (0, _emberRunloop.bind)(_this4, function (snapshot) {
          var findRecordPromises = [];

          if (snapshot.exists()) {
            snapshot.forEach(function (child) {
              findRecordPromises.push(_this4.findRecord(store, type, child.key));
            });

            RSVP.all(findRecordPromises).then((0, _emberRunloop.bind)(_this4, function (records) {
              _this4._setupListListener(store, modelName);
              ref.off('value');
              resolve(records);
            })).catch((0, _emberRunloop.bind)(_this4, function (error) {
              reject(error);
            }));
          } else {
            reject();
          }
        }), (0, _emberRunloop.bind)(_this4, function (error) {
          reject(error);
        }));
      }));
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {DS.Snapshot} snapshot
     * @return {Promise} Resolves once the record has been deleted
     */
    deleteRecord: function deleteRecord(store, type, snapshot) {
      var _this5 = this;

      return new RSVP.Promise((0, _emberRunloop.bind)(this, function (resolve, reject) {
        var modelName = type.modelName;
        var id = snapshot.id;
        var path = '/' + (0, _emberInflector.pluralize)(modelName) + '/' + id;
        var serializedInclude = _this5._serializeInclude(snapshot);
        var fanout = {};

        fanout[path] = null;
        fanout = (0, _emberPlatform.assign)({}, fanout, serializedInclude);

        _this5.get('firebase').update(fanout, (0, _emberRunloop.bind)(_this5, function (error) {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        }));
      }));
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {Object} [query={}]
     * @return {Promise} Resolves with the queried record
     */
    queryRecord: function queryRecord(store, type) {
      var _this6 = this;

      var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};

      return new RSVP.Promise((0, _emberRunloop.bind)(this, function (resolve, reject) {
        var path = query.path;
        var onValue = (0, _emberRunloop.bind)(_this6, function (snapshot) {
          if (snapshot.exists()) {
            // Will always loop once because of the forced limitTo* 1
            snapshot.forEach(function (child) {
              var snapshot = {};

              if (path && _typeof(child.val()) === 'object') {
                snapshot.adapterOptions = { path: path };
              }

              _this6.findRecord(store, type, child.key, snapshot).then(function (record) {
                ref.off('value', onValue);
                resolve(record);
              }).catch(function (error) {
                reject(error);
              });
            });
          } else {
            reject();
          }
        });

        var ref = path ? _this6.get('firebase').child(path) : _this6._getFirebaseReference(type.modelName);

        ref = _this6._setupQuerySortingAndFiltering(ref, query);

        ref.on('value', onValue, (0, _emberRunloop.bind)(_this6, function (error) {
          reject(error);
        }));
      }));
    },


    /**
     * @param {DS.Store} store
     * @param {DS.Model} type
     * @param {Object} [query={}]
     * @param {DS.AdapterPopulatedRecordArray} recordArray
     * @return {Promise} Resolves with the queried record
     */
    query: function query(store, type) {
      var _this7 = this;

      var query = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var recordArray = arguments[3];

      return new RSVP.Promise((0, _emberRunloop.bind)(this, function (resolve, reject) {
        var path = query.path;
        var hasCacheId = query.hasOwnProperty('cacheId');
        var modelName = type.modelName;
        var onValue = (0, _emberRunloop.bind)(_this7, function (snapshot) {
          var findRecordPromises = [];

          if (snapshot.exists()) {
            snapshot.forEach(function (child) {
              var snapshot = {};

              if (path && _typeof(child.val()) === 'object') {
                snapshot.adapterOptions = { path: path };
              }

              findRecordPromises.push(_this7.findRecord(store, type, child.key, snapshot));
            });
          }

          RSVP.all(findRecordPromises).then((0, _emberRunloop.bind)(_this7, function (records) {
            if (hasCacheId) {
              _this7._setupQueryListListener(store, modelName, recordArray, ref);
              _this7._trackQuery(query.cacheId, recordArray);
            }

            ref.off('value', onValue);
            resolve(records);
          })).catch((0, _emberRunloop.bind)(_this7, function (error) {
            reject(error);
          }));
        });

        var ref = path ? _this7.get('firebase').child(path) : _this7._getFirebaseReference(modelName);

        ref = _this7._setupQuerySortingAndFiltering(ref, query);

        ref.on('value', onValue, (0, _emberRunloop.bind)(_this7, function (error) {
          reject(error);
        }));
      }));
    },


    /**
     * @param {DS.Snapshot} snapshot
     * @return {Object} Serialized include
     * @private
     */
    _serializeInclude: function _serializeInclude(snapshot) {
      var newInclude = {};

      if (snapshot.hasOwnProperty('adapterOptions')) {
        var adapterOptions = snapshot.adapterOptions;

        if (adapterOptions && adapterOptions.hasOwnProperty('include')) {
          var include = adapterOptions.include;

          for (var key in include) {
            if (Object.prototype.hasOwnProperty.call(include, key)) {
              var newKey = key.replace('$id', snapshot.id);

              newInclude[newKey] = include[key];
            }
          }
        }
      }

      return newInclude;
    },


    /**
     * @param {DS.Store} store
     * @param {string} modelName
     * @param {string} id
     * @private
     */
    _setupValueListener: function _setupValueListener(store, modelName, id) {
      var _this8 = this;

      var fastboot = this.get('fastboot');

      if (!fastboot || !fastboot.get('isFastBoot')) {
        var path = '/' + (0, _emberInflector.pluralize)(modelName) + '/' + id;

        if (!this._isListenerTracked(path, 'value')) {
          this._trackListener(path, 'value');

          var _ref = this._getFirebaseReference(modelName, id);

          _ref.on('value', (0, _emberRunloop.bind)(this, function (snapshot) {
            if (snapshot.exists()) {
              var snapshotWithId = _this8._getGetSnapshotWithId(snapshot);
              var normalizedRecord = store.normalize(modelName, snapshotWithId);

              store.push(normalizedRecord);
            } else {
              _this8._unloadRecord(store, modelName, id);
            }
          }), (0, _emberRunloop.bind)(this, function (error) {
            _this8._unloadRecord(store, modelName, id);
          }));
        }
      }
    },


    /**
     * @param {DS.Store} store
     * @param {string} modelName
     * @private
     */
    _setupListListener: function _setupListListener(store, modelName) {
      var _this9 = this;

      var fastboot = this.get('fastboot');

      if (!fastboot || !fastboot.get('isFastBoot')) {
        var path = '/' + (0, _emberInflector.pluralize)(modelName);

        if (!this._isListenerTracked(path, 'child_added')) {
          this._trackListener(path, 'child_added');
          this._getFirebaseReference(modelName).on('child_added', function (snapshot) {
            _this9._setupValueListener(store, modelName, snapshot.key);
          });
        }
      }
    },


    /**
     * @param {DS.Store} store
     * @param {string} modelName
     * @param {DS.AdapterPopulatedRecordArray} recordArray
     * @param {firebase.database.DataSnapshot} ref
     * @private
     */
    _setupQueryListListener: function _setupQueryListListener(store, modelName, recordArray, ref) {
      var fastboot = this.get('fastboot');

      if (!fastboot || !fastboot.get('isFastBoot')) {
        var onChildAdded = (0, _emberRunloop.bind)(this, function (snapshot) {
          store.findRecord(modelName, snapshot.key).then(function (record) {
            // We're using a private API here and will likely break
            // without warning. We need to make sure that our acceptance
            // tests will capture this even if indirectly.
            recordArray.get('content').addObject(record._internalModel);
          });
        });

        ref.on('child_added', onChildAdded);

        var onChildRemoved = (0, _emberRunloop.bind)(this, function (snapshot) {
          var record = recordArray.get('content').findBy('id', snapshot.key);

          if (record) {
            recordArray.get('content').removeObject(record);
          }
        });

        ref.on('child_removed', onChildRemoved);

        this._setupRecordExtensions(recordArray, ref, onChildAdded, onChildRemoved);
      }
    },


    /**
     * @param {DS.AdapterPopulatedRecordArray} recordArray
     * @param {firebase.database.DataSnapshot} ref
     * @param {function} onChildAdded
     * @param {function} onChildRemoved
     * @private
     */
    _setupRecordExtensions: function _setupRecordExtensions(recordArray, ref, onChildAdded, onChildRemoved) {
      recordArray.set('firebase', {
        next: function next(numberOfRecords) {
          ref.off('child_added', onChildAdded);
          ref.off('child_removed', onChildRemoved);

          var query = recordArray.get('query');

          if (query.hasOwnProperty('limitToFirst')) {
            query.limitToFirst += numberOfRecords;
          }

          if (query.hasOwnProperty('limitToLast')) {
            query.limitToLast += numberOfRecords;
          }

          return recordArray.update();
        },
        off: function off() {
          ref.off('child_added', onChildAdded);
          ref.off('child_removed', onChildRemoved);
        }
      });
    },


    /**
     * @param {firebase.database.DataSnapshot} ref
     * @param {Object} query
     * @param {boolean} isForcingLimitToOne
     * @return {firebase.database.DataSnapshot} Reference with sort/filters
     * @private
     */
    _setupQuerySortingAndFiltering: function _setupQuerySortingAndFiltering(ref, query, isForcingLimitToOne) {
      if (!query.hasOwnProperty('orderBy')) {
        query.orderBy = 'id';
      }

      if (query.orderBy === 'id') {
        ref = ref.orderByKey();
      } else if (query.orderBy === '.value') {
        ref = ref.orderByValue();
      } else {
        ref = ref.orderByChild(query.orderBy);
      }

      if (isForcingLimitToOne) {
        if (query.hasOwnProperty('limitToFirst') || query.hasOwnProperty('limitToLast')) {
          if (query.hasOwnProperty('limitToFirst')) {
            query.limitToFirst = 1;
          } else {
            query.limitToLast = 1;
          }
        } else {
          query.limitToFirst = 1;
        }
      }

      ['startAt', 'endAt', 'equalTo', 'limitToFirst', 'limitToLast'].forEach(function (type) {
        if (query.hasOwnProperty(type)) {
          ref = ref[type](query[type]);
        }
      });

      return ref;
    },


    /**
     * @param {firebase.database.DataSnapshot} snapshot
     * @return {Object} Snapshot with ID
     * @private
     */
    _getGetSnapshotWithId: function _getGetSnapshotWithId(snapshot) {
      return (0, _emberPlatform.assign)({}, { id: snapshot.key }, snapshot.val());
    },


    /**
     * @param {string} modelName
     * @param {string} [id='']
     * @return {firebase.database.DataSnapshot} Firebase reference
     * @private
     */
    _getFirebaseReference: function _getFirebaseReference(modelName) {
      var id = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';

      var path = '/' + (0, _emberInflector.pluralize)(modelName) + '/' + id;

      return this.get('firebase').child(path);
    },


    /**
     * @param {DS.Store} store
     * @param {string} modelName
     * @param {string} id
     * @private
     */
    _unloadRecord: function _unloadRecord(store, modelName, id) {
      var record = store.peekRecord(modelName, id);

      if (record && !record.get('isSaving')) {
        store.unloadRecord(record);
      }
    },


    /**
     * @param {string} key trackedListeners key
     * @param {string} type Type of listener (value, child_added, etc.)
     * @return {boolean} True if already tracked. Otherwise, false.
     * @private
     */
    _isListenerTracked: function _isListenerTracked(key, type) {
      var trackedListeners = this.get('trackedListeners');

      return trackedListeners.hasOwnProperty(key) && trackedListeners[key][type];
    },


    /**
     * @param {string} key trackedListeners key
     * @param {string} type Type of listener (value, child_added, etc.)
     * @private
     */
    _trackListener: function _trackListener(key, type) {
      var trackedListeners = this.get('trackedListeners');
      var tempTrackedListeners = (0, _emberPlatform.assign)({}, trackedListeners);

      if (!tempTrackedListeners.hasOwnProperty(key)) {
        tempTrackedListeners[key] = {};
      }

      tempTrackedListeners[key][type] = true;

      this.set('trackedListeners', (0, _emberPlatform.assign)({}, trackedListeners, tempTrackedListeners));
    },


    /**
     * @param {string} cacheId
     * @param {DS.AdapterPopulatedRecordArray} recordArray
     * @private
     */
    _trackQuery: function _trackQuery(cacheId, recordArray) {
      var fastboot = this.get('fastboot');

      if (!fastboot || !fastboot.get('isFastBoot')) {
        var trackedQueries = this.get('trackedQueries');
        var trackedQueryCache = trackedQueries[cacheId];

        if (trackedQueryCache) {
          trackedQueryCache.get('firebase').off();
        }

        var trackedQuery = {};

        trackedQuery[cacheId] = recordArray;

        this.set('trackedQueries', (0, _emberPlatform.assign)({}, trackedQueries, trackedQuery));
      }
    }
  });
});