define('emberfire-utils/serializers/firebase-flex', ['exports', 'ember-inflector', 'ember-platform', 'emberfire/serializers/firebase'], function (exports, _emberInflector, _emberPlatform, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    /**
     * @param {DS.Snapshot} snapshot
     * @return {Object} Fanout object for Firebase
     */
    serialize: function serialize(snapshot) {
      var fanout = {};
      var path = this._getPath(snapshot);
      var changedAttributes = (0, _emberPlatform.assign)({}, snapshot.changedAttributes());

      snapshot.eachAttribute(function (key, attribute) {
        if (changedAttributes.hasOwnProperty(key)) {
          fanout[path + '/' + key] = snapshot.attr(key);
        }
      });

      // TODO: Implement updating changed relationships once Ember Data
      // supports tracking it. See directions of
      // https://github.com/emberjs/data/pull/3698
      // snapshot.eachRelationship((key, relationship) => {
      //   if (relationship.kind === 'belongsTo') {
      //     fanout[`${path}/${key}`] = snapshot.belongsTo(key, {
      //       id: true,
      //     });
      //   } else if (relationship.kind === 'hasMany') {
      //     const unformattedHasManyKey = `${modelName} ${key}`;
      //     const camelizedHasManyKey = camelize(unformattedHasManyKey);
      //     const pluralizedHasManyKey = pluralize(camelizedHasManyKey);

      //     snapshot.hasMany(key, { ids: true }).forEach((id) => {
      //       const path = `/${pluralizedHasManyKey}/${snapshotId}/${id}`;

      //       fanout[path] = true;
      //     });
      //   }
      // });

      return fanout;
    },


    /**
     * @param {DS.Snapshot} snapshot
     * @return {string} Firebase path
     * @private
     */
    _getPath: function _getPath(snapshot) {
      if (snapshot.adapterOptions && snapshot.adapterOptions.hasOwnProperty('path')) {
        var pathPrefix = snapshot.adapterOptions.path;

        if (pathPrefix) {
          if (!pathPrefix.startsWith('/')) {
            pathPrefix = '/' + pathPrefix;
          }

          return pathPrefix + '/' + snapshot.id;
        }
      }

      return '/' + (0, _emberInflector.pluralize)(snapshot.modelName) + '/' + snapshot.id;
    }
  });
});