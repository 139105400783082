define('ember-gestures/components/fast-async', ['exports', 'ember', 'ember-gestures/templates/components/fast-async', 'ember-gestures/components/async-element'], function (exports, _ember, _fastAsync, _asyncElement) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var SafeString = _ember.default.Handlebars.SafeString;
  exports.default = _asyncElement.default.extend({

    layout: _fastAsync.default,
    tagName: 'button',
    attributeBindings: ['style', 'disabled', 'type'],
    style: new SafeString('touch-action: manipulation; -ms-touch-action: manipulation;'),
    type: 'button',
    text: '',
    context: null

  });
});