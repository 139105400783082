define('emberfire-utils/services/firebase-util', ['exports', 'ember-array/utils', 'ember-platform', 'ember-utils', 'ember-service', 'ember-service/inject', 'ember-metal/set', 'ember-runloop'], function (exports, _utils, _emberPlatform, _emberUtils, _emberService, _inject, _set, _emberRunloop) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var RSVP = Ember.RSVP;
  exports.default = _emberService.default.extend({
    /**
     * @type Ember.Service
     * @readOnly
     */
    firebase: (0, _inject.default)(),

    /**
     * @type Ember.Service
     * @readOnly
     */
    firebaseApp: (0, _inject.default)(),

    /**
     * @type Ember.Service
     * @readOnly
     */
    store: (0, _inject.default)(),

    /**
     * @type Object
     * @private
     * @default null
     */
    _queryCache: null,

    /**
     * Service hook.
     *
     * - Set _queryCache to an empty object
     */
    init: function init() {
      this._super.apply(this, arguments);

      this.set('_queryCache', {});
    },


    /**
     * Uploads a file to Firebase storage
     *
     * @param {string} path Storage path
     * @param {Blob} file File to upload
     * @param {Object} [metadata={}] File metadata
     * @param {function} [onStateChange=() => {}]
     *    Function to call when state changes
     * @return {Promise.<string>} Download URL
     */
    uploadFile: function uploadFile(path, file) {
      var _this = this;

      var metadata = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : {};
      var onStateChange = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : function () {};

      return new RSVP.Promise(function (resolve, reject) {
        var uploadTask = _this.get('firebaseApp').storage().ref(path).put(file, metadata);

        uploadTask.on('state_changed', (0, _emberRunloop.bind)(_this, function (snapshot) {
          onStateChange(snapshot);
        }), (0, _emberRunloop.bind)(_this, function (error) {
          reject(error);
        }), (0, _emberRunloop.bind)(_this, function () {
          resolve(uploadTask.snapshot.downloadURL);
        }));
      });
    },


    /**
     * Delete a file from Firebase storage
     *
     * @param {string} url File HTTPS URL
     * @return {Promise} Resolves when deleted.
     */
    deleteFile: function deleteFile(url) {
      var _this2 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this2.get('firebaseApp').storage().refFromURL(url).delete().then((0, _emberRunloop.bind)(_this2, resolve)).catch((0, _emberRunloop.bind)(_this2, function (error) {
          return reject(error);
        }));
      });
    },


    /**
     * Writes to firebase natively in fan-out style
     *
     * @param {Object} fanoutObject Fan-out object to write
     * @return {Promise} Resolves when update succeeds
     */
    update: function update(fanoutObject) {
      var _this3 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this3.get('firebase').update(fanoutObject, (0, _emberRunloop.bind)(_this3, function (error) {
          if (error) {
            reject(error);
          } else {
            resolve();
          }
        }));
      });
    },


    /**
     * Finds record from a Firebase path. Any changes made under the
     * Firebase path will be synchronized in realtime.
     *
     * Similar to `store.findRecord()` except that this returns the record
     * in a plain object rather than a `DS.Model`.
     *
     * @param {string} listenerId Firebase listener ID
     * @param {string} path Path of records in Firebase
     * @return {Promise.<Object>} Resolves to the record
     */
    findRecord: function findRecord(listenerId, path) {
      var _this4 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var query = _this4.get('_queryCache')[listenerId];

        if (query) {
          (0, _emberRunloop.default)(null, resolve, query.record);
        } else {
          var ref = _this4.get('firebase').child(path);

          query = { ref: ref, path: path, record: {} };
          _this4.set('_queryCache.' + listenerId, query);

          ref.on('value', (0, _emberRunloop.bind)(_this4, function (snapshot) {
            if (snapshot.exists()) {
              _this4._assignObject(query.record, _this4._serialize(snapshot.key, snapshot.val()));
              resolve(query.record);
            } else {
              _this4._nullifyObject(query.record);
              resolve(query.record);
            }
          }), (0, _emberRunloop.bind)(_this4, function (error) {
            _this4._nullifyObject(query.record);
            reject(error);
          }));
        }
      });
    },


    /**
     * Finds all data from a Firebase path.
     *
     * Typically, it's bad practice to do a `value` listener on a path
     * that has multiple records due to the potential to download huge
     * amounts of data whenever a property changes. Thus, any changes
     * made under the Firebase path **won't** be synchronized in
     * realtime.
     *
     * Similar to `store.findAll()` except that this returns the records
     * in plain objects rather than a `DS.Model`.
     *
     * @param {string} path Path of records in Firebase
     * @return {Promise.<Array>} Resolves to all records
     */
    findAll: function findAll(path) {
      var _this5 = this;

      return new RSVP.Promise(function (resolve, reject) {
        var ref = _this5.get('firebase').child(path);

        ref.once('value').then((0, _emberRunloop.bind)(_this5, function (snapshot) {
          var records = [];

          if (snapshot.exists()) {
            snapshot.forEach(function (child) {
              records.push(_this5._serialize(child.key, child.val()));
            });
          }

          resolve(records);
        })).catch((0, _emberRunloop.bind)(_this5, function (error) {
          return reject(error);
        }));
      });
    },


    /**
     * Queries data from a Firebase path. Any changes made under the
     * Firebase path will be synchronized in realtime.
     *
     * This has the benefit of providing data for infinite scrolling
     * through the `firebaseUtil.next()` function.
     *
     * @param {string} modelName Model name of the records to query
     * @param {string} listenerId Firebase listener ID
     * @param {string} path Path of records in Firebase
     * @param {Object} [option={}] Query options
     * @return {Array.<Object>} Records
     */
    query: function query(modelName, listenerId, path) {
      var _this6 = this;

      var option = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};

      console.warn('DEPRECATION: firebase-util query() will be removed in ' + 'favor of firebase-flex adapter query()');

      return new RSVP.Promise(function (resolve, reject) {
        var query = _this6.get('_queryCache')[listenerId];

        if (!query) {
          var ref = _this6.get('firebase').child(path);

          query = {
            ref: ref,
            path: path,
            modelName: modelName,
            willUnshiftRecord: false,
            records: new _utils.A()
          };
          option.orderBy = option.hasOwnProperty('orderBy') ? option.orderBy : 'id';
          (0, _emberPlatform.assign)(query, option);
          _this6.set('_queryCache.' + listenerId, query);
          _this6._setQuerySortingAndFiltering(query);

          // Set an active listener to cache the data for child_added.
          // The child_added listener will turn this off later.
          query.ref.on('value', function () {});
          query.ref.once('value').then((0, _emberRunloop.bind)(_this6, function (snapshot) {
            if (snapshot.exists()) {
              var requests = Object.keys(snapshot.val()).map(function (key) {
                return _this6.get('store').findRecord(query.modelName, key);
              });

              RSVP.all(requests).then((0, _emberRunloop.bind)(_this6, function (records) {
                records.forEach(function (record) {
                  return query.records.pushObject(record);
                });
                _this6._setQueryListeners(query);
                resolve(query.records);
              }));
            } else {
              _this6._setQueryListeners(query);
              resolve(query.records);
            }
          })).catch((0, _emberRunloop.bind)(_this6, function (error) {
            return reject(error);
          }));
        } else {
          (0, _emberRunloop.default)(null, resolve, query.records);
        }
      });
    },


    /**
     * Load more records to id in _queryCache
     *
     * @param {string} listenerId Listener ID
     * @param {number} numberOfRecords Number of records to add
     * @return {Promise} Resolves when the next set of data has been loaded
     */
    next: function next(listenerId, numberOfRecords) {
      var _this7 = this;

      console.warn('DEPRECATION: firebase-util next() will be removed in favor ' + 'of firebase-flex adapter\'s own implementation');

      return new RSVP.Promise(function (resolve, reject) {
        var query = _this7.get('_queryCache')[listenerId];

        query.ref.off();
        query.ref = _this7.get('firebase').child(query.path);

        if (query.hasOwnProperty('limitToFirst')) {
          query.limitToFirst += numberOfRecords;
        }

        if (query.hasOwnProperty('limitToLast')) {
          query.limitToLast += numberOfRecords;
          query.willUnshiftRecord = true;
        }

        _this7._setQuerySortingAndFiltering(query);

        // Set an active listener to cache the data for child_added.
        // The child_added listener will turn this off later.
        query.ref.on('value', function () {});
        query.ref.once('value').then((0, _emberRunloop.bind)(_this7, function (snapshot) {
          if (snapshot.exists()) {
            var requests = [];

            Object.keys(snapshot.val()).forEach(function (key) {
              if (!query.records.findBy('id', key)) {
                requests.push(_this7.get('store').findRecord(query.modelName, key));
              }
            });

            RSVP.all(requests).then((0, _emberRunloop.bind)(_this7, function (records) {
              records.forEach(function (record) {
                if (query.willUnshiftRecord) {
                  query.records.unshiftObject(record);
                } else {
                  query.records.pushObject(record);
                }
              });

              _this7._setQueryListeners(query);
              resolve(query.records);
            }));
          } else {
            _this7._setQueryListeners(query);
            resolve(query.records);
          }
        })).catch((0, _emberRunloop.bind)(_this7, function (error) {
          return reject(error);
        }));
      });
    },


    /**
     * Checks if record exists in Firebase
     *
     * @param {string} path Firebase path
     * @return {Promise.<boolean>} Resolves to true if record exists.
     *    Otherwise false.
     */
    isRecordExisting: function isRecordExisting(path) {
      var _this8 = this;

      return new RSVP.Promise(function (resolve, reject) {
        _this8.get('firebase').child(path).once('value').then((0, _emberRunloop.bind)(_this8, function (snapshot) {
          resolve(snapshot.exists());
        })).catch((0, _emberRunloop.bind)(_this8, function (error) {
          return reject(error);
        }));
      });
    },


    /**
     * Generate a Firebase push ID for a path
     *
     * @param {string} path Firebase path
     * @return {string} Push ID
     */
    generateIdForRecord: function generateIdForRecord(path) {
      return this.get('firebase').child(path).push().key;
    },


    /**
     * Serializes the record.
     *
     * If `key` has a value of `'foo'` and `value` has a value of `true`,
     * it'll serialize it into this format:
     *
     * ```javascript
     * {
     *   id: 'foo',
     *   value: true
     * }
     * ```
     *
     * If `key` has a value of `'foo'` and `value` has a value of
     * `{name: 'bar'}`, it'll serialize it into this format:
     *
     * ```javascript
     * {
     *   id: 'foo',
     *   name: 'bar'
     * }
     * ```
     *
     * @param {string} key Record key
     * @param {(string|Object)} value Record value
     * @return {Object} Serialized record
     * @private
     */
    _serialize: function _serialize(key, value) {
      var record = void 0;

      if ((0, _emberUtils.typeOf)(value) === 'object') {
        record = value;
        record.id = key;
      } else {
        record = { id: key, value: value };
      }

      return record;
    },


    /**
     * Polyfill workaround for `Object.assign` on an `Ember.Object` object
     * property.
     *
     * @param {Object} objectToUpdate Object to update
     * @param {Object} objectToMerge Object to merge
     * @private
     */
    _assignObject: function _assignObject(objectToUpdate, objectToMerge) {
      for (var key in objectToMerge) {
        if (Object.prototype.hasOwnProperty.call(objectToMerge, key)) {
          (0, _set.default)(objectToUpdate, key, objectToMerge[key]);
        }
      }
    },


    /**
     * Set all the object key's value to null
     *
     * @param {Object} object Object to clear
     * @private
     */
    _nullifyObject: function _nullifyObject(object) {
      for (var key in object) {
        if (Object.prototype.hasOwnProperty.call(object, key)) {
          (0, _set.default)(object, key, null);
        }
      }
    },


    /**
     * Set the query sorting and filtering
     *
     * @param {Object} query Query object
     * @private
     */
    _setQuerySortingAndFiltering: function _setQuerySortingAndFiltering(query) {
      if (query.orderBy === 'id') {
        query.ref = query.ref.orderByKey();
      } else if (query.orderBy === '.value') {
        query.ref = query.ref.orderByValue();
      } else {
        query.ref = query.ref.orderByChild(query.orderBy);
      }

      ['startAt', 'endAt', 'equalTo', 'limitToFirst', 'limitToLast'].forEach(function (type) {
        if (query.hasOwnProperty(type)) {
          query.ref = query.ref[type](query[type]);
        }
      });
    },


    /**
     * Set the query listeners
     *
     * @param {Object} query Query object
     * @private
     */
    _setQueryListeners: function _setQueryListeners(query) {
      var _this9 = this;

      query.ref.on('child_added', (0, _emberRunloop.bind)(this, function (snapshot) {
        // Turn off the active value listener since the child_added is
        // now in responsible for caching the data.
        query.ref.off('value');

        var key = snapshot.key;

        if (!query.records.findBy('id', key)) {
          var recordIndex = void 0;
          var tempRecord = { id: key, isLoading: true };

          if (query.willUnshiftRecord) {
            query.records.unshiftObject(tempRecord);
            recordIndex = 1;
          } else {
            query.records.pushObject(tempRecord);
            recordIndex = query.records.get('length');
          }

          _this9.get('store').findRecord(query.modelName, key).then(function (record) {
            query.records.insertAt(recordIndex, record);
            query.records.removeObject(tempRecord);
          });
        }
      }), (0, _emberRunloop.bind)(this, query.records.clear));

      query.ref.on('child_removed', (0, _emberRunloop.bind)(this, function (snapshot) {
        var record = query.records.findBy('id', snapshot.key);

        if (record) {
          query.records.removeObject(record);
        }
      }), (0, _emberRunloop.bind)(this, query.records.clear));
    }
  });
});